<template>
    <div id="app">
        

        <section class="home-section" id="home_wrapper">
            <div class="home-section-overlay"></div>
            <!--begin container -->
            <div class="container">
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-7-->
                    <div class="col-md-7 padding-top-50">
                        <h1>Sign Up for Updates!</h1>
                        <p>Give us your details so we can let you know once you can join our closed beta. You get to use ContactCentre free until we go live!</p>
                        <!--begin register form-->
                        <div class="register-form-wrapper">
                            <!--begin register form -->
                            <form id="app" class="register-form register">
                                <!--begin col-md-6-->
                                <div class="col-md-6 no-padding-left">
                                    <input class="form-control input-lg" required="" placeholder="Full Name*" type="text"
                                           style="background-color:transparent;color:white;margin-bottom:36px;"
                                           v-model="fullName">
                                </div>
                                <!--end col-md-6-->
                                <!--begin col-md-6-->
                                <div class="col-md-6 no-padding-right">
                                    <input class="form-control input-lg" required="" placeholder="Email Address*" type="email"
                                           style="background-color:transparent;color:white;margin-bottom:36px;"
                                           v-model="emailAddress">
                                </div>
                                <!--end col-md-6-->
                                <!--begin col-md-12-->
                                <div class="col-md-12 no-padding-left no-padding-right">
                                    <input value="Submit" class="register-submit-top" type="button"
                                           v-bind:disabled="submitDisabled" v-on:click="submit">
                                </div>
                                <!--end col-md-12-->
                            </form>
                            <!--end register form -->
                        </div>
                        <!--end register form-->
                    </div>
                    <!--end col-md-7-->
                    <!--begin col-md-5-->
                    <div class="col-md-5 wow bounceIn" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: bounceIn;">
                        <img src="/store/images/phone5.png" class="width-100" alt="pic">
                    </div>
                    <!--end col-md-5-->
                </div>
                <!--end row -->
            </div>
            <!--end container -->
        </section>

        <section class="section-grey small-padding-top" id="about">
            <!--begin container -->
            <div class="container">
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center">
                        <h2 class="section-title">Building Bridges, Connecting Communities</h2>
                        <p class="section-subtitle text-justify">
                            Pronova is a start up dedicated to creating <b>technology ecosystems</b>.
                            <a href="https://hbswk.hbs.edu/item/information-technology-ecosystem-health-and-performance" target="_blank">
                                Harvard Business School
                            </a> defines a technology ecosystem as "the network of organizations that drives the
                            creation and delivery of information technology products and services."
                        </p>
                        <p class="section-subtitle text-justify">
                            At Pronova, we use bespoke automation and data analytics products to drive technology ecosystems that help you unlock
                            more value in your business and solve problems across an entire industry, or a cluster of industries.
                        </p>
                    </div>
                    <!--end col-md-12 -->
                </div>
                <!--end row -->
            </div>
            <!--end container -->
        </section>

        <section class="section-white">
            <!--begin container -->
            <div class="container">
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-12 -->
                    <div class="col-md-12 margin-bottom-40">
                        <h2 class="section-title text-center">Introducing ContactCentre</h2>

                        <div class="row">
                            <!--begin col-md-4 -->
                            <div class="col-md-4">
                                <div class="main-services">
                                    <img src="/store/images/question_mark.png" class="width-100" alt="pic">
                                    <h3>Do You Know...</h3>
                                    <p>...whether your staff return calls from customers and potential customers in a polite and prompt manner?</p>
                                </div>
                            </div>
                            <!--end col-md-4 -->
                            <!--begin col-md-4 -->
                            <div class="col-md-4">
                                <div class="main-services">
                                    <img src="/store/images/question_mark.png" class="width-100" alt="pic">
                                    <h3>Do You Know...</h3>
                                    <p>...whether or not your staff make and receive personal calls on the business line?</p>
                                </div>
                            </div>
                            <!--end col-md-4 -->
                            <!--begin col-md-4 -->
                            <div class="col-md-4">
                                <div class="main-services">
                                    <img src="/store/images/question_mark.png" class="width-100" alt="pic">
                                    <h3>Do You Wish...</h3>
                                    <p>...you could review a sample of calls and text messages handled by your staff for quality assurance purposes?</p>
                                </div>
                            </div>
                            <!--end col-md-4 -->
                        </div>
                        <div class="margin-top-40">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="section-subtitle text-justify">
                                        ContactCentre is a <b>virtual call centre</b> platform provided as a service to small and medium-scale businesses.
                                        Instead of having you purchase expensive hardware and software to create a brick-and-mortar call centre,
                                        ContactCentre allows you to continue to use your existing smartphones as the primary devices for communicating with
                                        your customers, and aggregates data from those smartphones into a cloud-based web application for your
                                        review and management.
                                    </p>
                                    <p class="section-subtitle text-justify">
                                        ContactCentre consists of a mobile application and a web application.
                                    </p>

                                </div>
                                <div class="col-md-6">
                                    <p class="section-subtitle text-justify">
                                        A subscriber typically uses the cloud-hosted web application to create and setup a virtual call centre, after
                                        which they register as many smartphone-based business telephone lines as they require. The subscriber then installs
                                        the ContactCentre mobile application on those smartphones.
                                    </p>

                                    <p class="section-subtitle text-justify">
                                        Once a smartphone is properly configured to use ContactCentre, it will capture all audio recordings of telephone
                                        calls made and received, as well as text messages sent and received and upload them to the web application.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-12 -->
                    <!--begin col-md-4 -->
                    <div class="col-md-4 padding-top-40">
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_right">
                                <span class="fas fa-money-bill-wave features_icons"></span>
                            </div>

                            <div class="text_align_right">
                                <h4>No Initial Investment</h4>
                                <p>
                                    No hardware or software purchases. Just install our Mobile Communications Monitor on your smartphones.
                                </p>
                            </div>
                        </div>
                        <!--end features_item -->
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_right">
                                <span class="fas fa-piggy-bank features_icons"></span>
                            </div>
                            <div class="text_align_right">
                                <h4>Low Running Costs</h4>
                                <p>Our monthly fees are affordable and you only pay for what you use.</p>
                            </div>
                        </div>
                        <!--end features_item -->
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_right">
                                <span class="fas fa-clock features_icons"></span>
                            </div>
                            <div class="text_align_right">
                                <h4>Fast Deployment Time</h4>
                                <p>You can complete the entire setup in less than 5 minutes.</p>
                            </div>
                        </div>
                        <!--end features_item -->
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4 -->
                    <div class="col-md-4 wow slideInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: bounceIn;">
                        <img src="/store/images/phone1.png" alt="picture" class="width-100">
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4 -->
                    <div class="col-md-4 padding-top-40">
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_left">
                                <span class="fas fa-phone-alt features_icons"></span>
                            </div>

                            <div class="text_align_left">
                                <h4>Monitor Phone Calls</h4>
                                <p>You can listen to phone calls made and received on all your business lines. You can generate a transcript of any call.</p>
                            </div>
                        </div>
                        <!--end features_item -->
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_left">
                                <span class="fas fa-sms features_icons"></span>
                            </div>

                            <div class="text_align_left">
                                <h4>Monitor Text Messages</h4>
                                <p>You can read text messages sent and received on all your business lines.</p>
                            </div>
                        </div>
                        <!--end features_item -->
                        <!--begin features_item -->
                        <div class="features_item">
                            <div class="dropcaps_left" style="margin-bottom:120px;">
                                <span class="fa fa-comments features_icons"></span>
                            </div>

                            <div class="text_align_left">
                                <h4>Create Tasks and Invoices</h4>
                                <p>
                                    You can create tasks for your staff to complete.
                                    You can raise invoices for your customer to pay.
                                    The web application gets notified when a task is completed or an invoice is paid.
                                </p>
                            </div>



                        </div>

                        <!--end features_item -->



                    </div>

                    <!--end col-md-4 -->



                </div>

                <!--end row -->



            </div>

            <!--end container -->
        </section>

        <section class="section-grey partners-paddings">
            <div class="container">
                <div class="row">
                    <div class="col-sm-5">
                        <h4 class="our-partners-title">
                            Is My Data Safe with Pronova?<br>
                            <span>Yes, all our servers are hosted by Amazon!</span>
                        </h4>
                    </div>
                    <div class="col-sm-7">
                        <!--begin partners-mentions -->
                        <ul class="partners-mentions">
                            <li>
                                <a href="https://aws.amazon.com/what-is-cloud-computing">
                                    <img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing">
                                </a>
                            </li>
                        </ul>
                        <!--end partners-mentions -->
                    </div>
                </div>
            </div>
        </section>

        <section class="section-white small-padding-bottom" id="features">
            <!--begin container -->
            <div class="container">
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-12-->
                    <div class="col-md-12 text-center padding-bottom-10">
                        <h2 class="section-title">Features</h2>
                        <p class="section-subtitle">
                            The ContactCentre mobile application and the ContactCentre web application combine effectively to deliver a great solution!
                        </p>
                    </div>
                    <!--end col-md-12 -->
                </div>
                <!--end row -->
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box light-red wow fadeIn" data-wow-delay="0.25s"
                             style="visibility: visible; animation-delay: 0.25s; animation-name: fadeIn;">
                            <i class="fas fa-phone-alt"></i>
                            <div class="feature-box-text">
                                <h4>Track Phone Calls</h4>
                                <p>
                                    Using the web application, you can listen to all calls made and received on all the mobile phones in
                                    your virtual call centre. You can also view all call records, even if your staff has deleted them
                                    from the phone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box light-green wow fadeIn" data-wow-delay="0.5s"
                             style="visibility: visible; animation-delay: 0.5s; animation-name: fadeIn;">
                            <i class="fas fa-sms"></i>
                            <div class="feature-box-text">
                                <h4>Track Text Messages</h4>
                                <p>
                                    Using the web application, you can read all text messages sent and received on all the mobile phones in
                                    your virtual call centre, even if your staff has deleted them from the phone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md 4-->
                    <div class="col-md-4">
                        <div class="feature-box light-blue wow fadeIn" data-wow-delay="0.75s"
                             style="visibility: visible; animation-delay: 0.75s; animation-name: fadeIn;">
                            <i class="fab fa-whatsapp"></i>
                            <div class="feature-box-text">
                                <h4 class="text-nowrap">Track WhatsApp Messages</h4>
                                <span class="small" style="color:crimson">Coming Soon!</span>
                                <p>
                                    Using the web application, you can read all WhatsApp messages sent and received to your WhatsApp
                                    for Business numbers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                </div>
                <!--end row -->
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box red wow fadeIn" data-wow-delay="1s"
                             style="visibility: visible; animation-delay: 1s; animation-name: fadeIn;">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="feature-box-text">
                                <h4>Track Location</h4>
                                <p>
                                    Using the web application, you can view the current and historical location of any one of your mobile
                                    phones. This feature is useful for businesses with field agents and other staff who are almost always
                                    outside the office.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box green wow fadeIn" data-wow-delay="1.25s" style="visibility: visible; animation-delay: 1.25s; animation-name: fadeIn;">
                            <i class="fas fa-battery-three-quarters"></i>
                            <div class="feature-box-text">
                                <h4>Track Battery Level</h4>
                                <p>
                                    Using the web application, you can view the current and historical battery level of any one of your mobile
                                    phones. This feature is useful for businesses with field agents and other staff who are almost always
                                    outside the office.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box blue wow fadeIn" data-wow-delay="1.5s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeIn;">
                            <i class="fab fa-instagram"></i>
                            <div class="feature-box-text">
                                <h4 class="text-nowrap">Track Instagram Messages</h4>
                                <span class="small" style="color:crimson">Coming Soon!</span>
                                <p>
                                    Using the web application, you can read all messages sent and received via your Instagram account,
                                    even if your staff has deleted the messages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                </div>
                <!--end row -->
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box dark-red wow fadeIn" data-wow-delay="1s"
                             style="visibility: visible; animation-delay: 1s; animation-name: fadeIn;">
                            <i class="fas fa-file-alt"></i>
                            <div class="feature-box-text">
                                <h4>Store Documents</h4>
                                <span class="small" style="color:crimson">Coming Soon!</span>
                                <p>
                                    Using the web application, you can store all documents pertaining to a single customer in one location online,
                                    eliminating the inconvenience of paper files.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box dark-green wow fadeIn" data-wow-delay="1.25s"
                             style="visibility: visible; animation-delay: 1.25s; animation-name: fadeIn;">
                            <i class="fas fa-tasks"></i>
                            <div class="feature-box-text">
                                <h4>Create Tasks</h4>
                                <span class="small" style="color:crimson">Coming Soon!</span>
                                <p>
                                    Using the web application, you can create tasks and assign them to your staff, and track them to completion.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                    <!--begin col-md-4-->
                    <div class="col-md-4">
                        <div class="feature-box dark-blue wow fadeIn" data-wow-delay="1.5s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeIn;">
                            <i class="fas fa-money-bill-wave"></i>
                            <div class="feature-box-text">
                                <h4 class="text-nowrap">Raise Invoices</h4>
                                <span class="small" style="color:crimson">Coming Soon!</span>
                                <p>
                                    Using the web application, you can raise invoices for your customers to pay. The web application can detect
                                    payment (even via bank transfers) and immediately prompt your staff to fulfill the customer's order.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end col-md-4 -->
                </div>
                <!--end row -->
                <!--begin row -->
                <div class="row">



                    <!--begin col-md-12-->
                    <!--<div class="col-md-12 padding-top-40">
                    <img src="/store/images/phone6.png" alt="home-iphone" class="extra-image width-100 wow fadeInUp" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">

                </div>-->
                    <!--end col-md-4 -->



                </div>

                <!--end row -->



            </div>

            <!--end container -->



        </section>

        <section class="section-lyla-shape newsletter_box" id="newsletter-section">



            <!--begin container -->

            <div class="container">



                <!--begin newsletter_box-->

                <div class="newsletter_box">



                    <!--begin row -->

                    <div class="row">



                        <!--begin col-md-12 -->

                        <div class="col-md-12 text-center padding-top-20 padding-bottom-20">



                            <h3 class="white-text">
                                Seen enough? We are currently in the closed beta.<br>

                                Please enter your email to join the waiting list.
                            </h3>

                            <div class="col-md-offset-3 col-md-6">
                                <!--begin register form-->
                                <div class="register-form-wrapper">
                                    <!--begin register form -->
                                    <form id="app" class="register-form register">
                                        <!--begin col-md-6-->
                                        <div class="col-md-6 no-padding-left">
                                            <input class="form-control input-lg" required="" placeholder="Full Name*" type="text"
                                                   style="background-color:transparent;color:white;margin-bottom:36px;"
                                                   v-model="fullName">
                                        </div>
                                        <!--end col-md-6-->
                                        <!--begin col-md-6-->
                                        <div class="col-md-6 no-padding-right">
                                            <input class="form-control input-lg" required="" placeholder="Email Address*" type="email"
                                                   style="background-color:transparent;color:white;margin-bottom:36px;"
                                                   v-model="emailAddress">
                                        </div>
                                        <!--end col-md-6-->
                                        <!--begin col-md-12-->
                                        <div class="col-md-12 no-padding-left no-padding-right">
                                            <input value="Submit" class="register-submit-top" type="button"
                                                   v-bind:disabled="submitDisabled" v-on:click="submit">
                                        </div>
                                        <!--end col-md-12-->
                                    </form>
                                    <!--end register form -->
                                </div>
                                <!--end register form-->
                            </div>

                        </div>

                        <!--end col-md-12 -->
                    </div>

                    <!--end row -->



                </div>

                <!--end newsletter_box -->



            </div>

            <!--end container -->
        </section>

        <div class="footer">
            <!--begin container -->
            <div class="container">
                <!--begin row -->
                <div class="row">
                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center">
                        <p>Copyright �2019 Pronova. All rights reserved.</p>

                        <!--begin footer_social -->
                        <ul class="footer_social">
                            <li>
                                <a href="https://web.facebook.com/contactcentre24">
                                    <i class="fab fa-facebook-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/contactcentre24/">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/contactcentre24">
                                    <i class="fab fa-twitter-square"></i>
                                </a>
                            </li>
                        </ul>
                        <!--end footer_social -->
                    </div>
                    <!--end col-md-6 -->
                </div>
                <!--end row -->
            </div>
            <!--end container -->
        </div>


    </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
